import {SELECTED_TEST_ID, GRADE_AND_COURSE, SELECTED_TEST_INFO, SEARCH_VALUES, TEST_PAPER_CURRENT_PAGE, TEST_PAPER_SEARCH}  from './type';

// 题目管理-选择作业id
export const setSelectedTestPaperId = (id:number) => {
    return {
        type:SELECTED_TEST_ID,
        id
    }
}

// 年级列表和科目列表
export const setElementList = (gradeList:any, courseList:any) => {
    return {
        type:GRADE_AND_COURSE,
        gradeList,
        courseList
    }
}

// 组建作业-设置选中作业的分值 时间 顺序
export const setSelectedTestInfo = (info:any) => {
    return {
        type:SELECTED_TEST_INFO,
        info
    }
}

// 组建作业-设置查询条件的值
export const setSearchValue = (values:any) => {
    return {
        type:SEARCH_VALUES,
        values
    }
}

// 设置作业管理的当前页数
export const setTestPaperCurrentPage = (page:number) => {
    return {
        type:TEST_PAPER_CURRENT_PAGE,
        page
    }
}

// 设置作业管理的查询参数
export const setTestSearchParams = (params:any) => {
    return {
        type:TEST_PAPER_SEARCH,
        params
    }
}