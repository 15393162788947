import {getSession} from './sessionStorage';

// 获取用户ID
export const getUserId = () => {
    const userInfo = getSession('userInfo');
    const _userInfo = userInfo ? JSON.parse(userInfo) : '';
    return _userInfo?.id;
}

// 获取用户名
export const getUserName = () => {
    const userInfo = getSession('userInfo');
    const _userInfo = userInfo ? JSON.parse(userInfo) : '';
    return _userInfo?.userName;
}

// 获取用户角色ID
export const getUserRoleId = () => {
    const userInfo = getSession('userInfo');
    const _userInfo = userInfo ? JSON.parse(userInfo) : '';
    return _userInfo?.primaryRoleId;
}

// 获取用户角色名称
export const getUserRoleName = () => {
    const userInfo = getSession('userInfo');
    const _userInfo = userInfo ? JSON.parse(userInfo) : '';
    return _userInfo?.primaryRoleName;
}

// 获取学校管理员学校名称
export const getschoolName = () => {
    const userInfo = getSession('userInfo');
    const _userInfo = userInfo ? JSON.parse(userInfo) : '';
    return _userInfo?.schoolName;
}

// 获取用户创建作业的初始选项
export const getUserCreateInitData = () => {
    const userInfo = getSession('userInfo');
    const _userInfo = userInfo ? JSON.parse(userInfo) : '';
    return _userInfo.initData;
    // {
    //     phaseCode:_userInfo?.phaseCode,
    //     courseCode:_userInfo?.courseCode,
    //     semesterCode:_userInfo?.semesterCode,
    //     editionCode:_userInfo?.editionCode
    // }
}