import { useEffect } from "react";
import { Form, Input, Button } from 'antd';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { fetchLogin } from '../../api/login';
import { setSession, clearSession } from '../../utils/sessionStorage';
import {CopyrightOutlined, LockOutlined, UserOutlined} from '@ant-design/icons';
// import Defaults from 'default-emulator';

const Login1 = () => {
    const navigatePage = useNavigate();

    useEffect(() => {
        clearSession();
    }, [])

    const handleLogin = async (values:any) => {
        try{
            const {accessToken, userData} = await fetchLogin(values);
            setSession('accessToken', accessToken);
            const userInfo = {
                id:userData.id,
                userName:userData.userName,
                primaryRoleName:userData.primaryRoleName,
                primaryRoleId:userData.primaryRoleId,
                schoolName:userData.schoolName,
                initData:{
                    phaseCode:userData.phaseCode,
                    courseCode:userData.courseCode,
                    semesterCode:userData.semesterCode,
                    editionCode:userData.editionCode
                }
            }
            setSession('userInfo', JSON.stringify(userInfo));
            navigatePage('/main');
        } catch(err){
            console.log(err);
        }
    }

    return (
        <div className="login_wrap">
            <div className="login_img">
                <img src={require("../../static/images/login_1.png")} alt="" />
            </div>
            <div className="login_box">
                <h1>易维科技-家庭作业小助手管理系统登录</h1>
                <Form
                    onFinish={handleLogin}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: '请输入用户名' }]}
                    >
                        <Input prefix={<UserOutlined />} placeholder='请输入用户名' />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: '请输入密码' }]}
                    >
                        <Input.Password prefix={<LockOutlined />} placeholder='请输入密码'/>
                    </Form.Item>

                    <Button type="primary" htmlType="submit" block>
                        登录
                    </Button>
                </Form>
            </div>
            <footer className="footer">
                <h3>版权所有 <CopyrightOutlined /> 深圳前海易维教育科技有限公司</h3>
                <h3>
                    <img src={require("../../static/images/gongan.png")} alt="" />
                    <a href="https://beian.miit.gov.cn/" rel="noreferrer"  target="_blank"> 粤ICP备18085588号-1 </ a>
                </h3>
            </footer>
        </div>
    )
}

export default Login1;