export const setSession = (key:string, value:string) => {
    return window.sessionStorage.setItem(key, value);
}

export const getSession = (key:string) => {
    return window.sessionStorage.getItem(key) || '';
}

export const removeSession = (key:string) => {
    return window.sessionStorage.removeItem(key);
}

export const clearSession = () => {
    return window.sessionStorage.clear();
}
