import Axios from 'axios';
import { message } from 'antd';
import { getSession } from './sessionStorage';
import LoadingPublisher from './loadingPublisher';

export const baseURL: string = 'https://www.ivy-technology.cn/paper/v1/';

const HttpRequest = Axios.create({
    baseURL,
    timeout: 60 * 1000, // 请求超时事件，如果超时请求终止
    headers:{
        // 'Content-Type':'application/x-www-form-urlencoded'
    }
});

// 请求拦截
HttpRequest.interceptors.request.use((config:any) => {
    config.headers['Authorization'] = getSession("accessToken");
    if(config && !config.ignoreLoading){
        LoadingPublisher.add();
    }
    return config;
}, (err:any) => {
    message.error(err.message);
   throw err;
});

// 响应拦截
HttpRequest.interceptors.response.use((response:any) => {
    const {status, data, request } = response;
    LoadingPublisher.sub();
    if(status === 200 || status === 201){
        if(data.status === "success"){
            return data;
        } else if(request.responseURL.includes('/paper/v1/get')){ // 如果是下载文件直接返回data
            return data;
        } else {
            const errorMsg = data.msg || "参数错误";
            message.error(errorMsg);
            const err = {
                message:errorMsg
            };
            throw err;
        }
    }
}, (err:any) => {
    LoadingPublisher.sub();
    const msg = (err.message || '').includes('timeout') ? '请求超时，请稍后再试' : err.message;
    message.error(msg);
    throw err;
})

export default HttpRequest;
