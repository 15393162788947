import HttpRequest from '../utils/httpRequest';

// 获取基本元素列表 [年级和课程列表]
export const fetchElementList = ():any => {
    return HttpRequest({
        method:'get',
        url:'/element/list'
    })
}

// 获取学校列表
export const fetchSchoolList = ():any => {
    return HttpRequest({
        method:'get',
        url:'/school/list'
    })
}

// 新增学校
export const fetchAddSchool = (data:any) => {
    return HttpRequest({
        method:'post',
        url:'/element/school',
        data
    })
}

// 查询某学校下的班级列表
export const fetchClassList = (schoolMemoId:string, gradeMemoId?:string):any => {
    return HttpRequest({
        method:'get',
        url:`/school/${schoolMemoId}/class/list`,
        params:{
            grade:gradeMemoId
        }
    })
}

// 新增班级
export const fetchAddClass = (data:any) => {
    return HttpRequest({
        method:'post',
        url:'/school/class',
        data
    })
}