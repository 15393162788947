import {SELECTED_TEST_ID, GRADE_AND_COURSE, SELECTED_TEST_INFO, SEARCH_VALUES, TEST_PAPER_CURRENT_PAGE, TEST_PAPER_SEARCH}  from './type';

const initState = {
    selectedTestPaperId:null,
    gradeList:[],
    courseList:[],
    selectedTestInfo:[],
    testPaperCurrentPage:0,
    searchValues:{
        phase:[],
        edition:'',
        paperType:1,
        diff:'',
        type:'',
        keyword:'',
        keypoint:''
    },
    testPaperSearchParams:{
        date:[null, null], 
        school:null,
        grade:null,
        course:null,
    }
}

const reducers = (state = initState, action:any) => {
    switch(action.type){
        case SELECTED_TEST_ID:
            return {
                ...state,
                selectedTestPaperId:action.id
            }
        case GRADE_AND_COURSE:
            return {
                ...state,
                gradeList:action.gradeList,
                courseList:action.courseList
            }
        case SELECTED_TEST_INFO:
            return {
                ...state,
                selectedTestInfo:action.info
            }
        case SEARCH_VALUES:
            return {
                ...state,
                searchValues:action.values
            }
        case TEST_PAPER_CURRENT_PAGE:
            return {
                ...state,
                testPaperCurrentPage:action.page
            }
        case TEST_PAPER_SEARCH:
            return {
                ...state,
                testPaperSearchParams:action.params
            }
        default:
            return state;
    }
}

export default reducers;