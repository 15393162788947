import { useEffect, useState, Suspense } from "react";
import { Layout, Menu, Avatar, Dropdown, message } from 'antd';
import './index.scss';
import { Outlet, Link, useNavigate, useLocation} from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import RouterData from '../router/routerData';
import { fetchElementList } from '../api/schoolManage';
import {setElementList} from '../store/action';
import {useDispatch} from 'react-redux';
import Loading from '../components/Loading/Loading';
import {getschoolName, getUserName, getUserRoleName} from '../utils/getInfo';

const { Header} = Layout;

const App = () => {
    const navigate = useNavigate();
    const [selectedKey, setSelectedKeys] = useState('');
    const {pathname} = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if(!getUserName()){
            message.warn('请先登录');
            navigate('/login', {replace:true});
            return () => {};
        }

        // 存储在全局 减少每次获取调用接口
        const getElementList = async () => {
            try{
                const { object } = await fetchElementList();
                dispatch(setElementList(object.grade, object.course));
            }catch(err){
                console.log(err);
            }
        }
        getElementList();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(pathname){
            const routeItem = RouterData.find((item) => item.path === pathname);
            setSelectedKeys(routeItem?.meta.menuKey || '');
        }
    }, [pathname])

    const menu = (
        <Menu>
            <Menu.Item key="1"> <Link to="/login">退出登录</Link> </Menu.Item>
        </Menu>
    )

    return (
        <div className="app_layout"> 
            <Header className="app_header">
                <div className="title">易维科技-家庭作业小助手管理系统 <span>{getschoolName()}</span></div>
                <Dropdown overlay={menu} arrow>
                    <div className="user">
                        <Avatar size="large" icon={<UserOutlined />} />
                        <div className="user_info">
                            <span>{getUserName()}</span>
                            <span>{getUserRoleName()}</span>
                        </div>
                    </div>
                </Dropdown>
            </Header>

            <div className="app_body">
                <ul className="app_menu">
                    {
                        RouterData.map((item) => {
                            const {render, menuKey} = item.meta;
                            // || !roles?.includes(getUserRoleId())
                            if(!render){
                                return false;
                            }
                            return (
                                <li key={menuKey} className={`menu_item ${selectedKey === menuKey ? 'active' : ''}`}>
                                    <Link to={item.path}>{item.title}</Link>
                                </li>
                            )
                        })
                    }
                </ul>
                <div className="content_box">
                    <Loading />
                    <Suspense fallback={'加载页面...'}>
                        <Outlet />
                    </Suspense>  
                </div>
            </div>
        </div>
    )
}

export default App;