import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from '../pages/Login/Login';
import App from '../pages/index';
import RouterData from './routerData';

const RenderRouter = () => (
    <Router>
        <Routes>          
            <Route path='/' element={<Navigate to='/login' />} />
            <Route path="/login" element={<Login />} />
            <Route path='/main' element={<App />}>
                {
                    RouterData.map((item, index) => {
                        const Element = item.element;
                        return (
                            <Route key={index} path={item.path} element={<Element />} />
                        )
                    })
                }
            </Route>
        </Routes>
    </Router>
)

export default RenderRouter;