import {Spin} from 'antd';
import {useEffect, useState} from 'react';
import loadingPublisher from '../../utils/loadingPublisher';
import './style.scss';

const Loading = () => {
    const [globalLoading, setGlobalLoading] = useState(false);

    useEffect(() => {
        loadingPublisher.subscribe((loadCount:number) => {
            if(globalLoading !== !!loadCount){
                setGlobalLoading(!!loadCount);
            }
        })
    }, [globalLoading])

    return (
        globalLoading ? 
        <Spin
            spinning={globalLoading} 
            tip='加载中...' 
            className='spin' 
            size='large'
        />
        : null
    )
}

export default Loading;