import {lazy} from 'react';
// import {ReadOutlined, UserAddOutlined, BankOutlined, ContainerOutlined, FileDoneOutlined,} from '@ant-design/icons';

const RouterData = [
    {
        path:'/main/testPaperManage',
        element:lazy(() => import('../pages/TestPaperManage/TestPaperManage')),
        title:'作业管理',
        meta:{
            // icon:ReadOutlined,
            menuKey:"testPaperManage",
            render:true,
            roles:[1, 100],
        }
    },
    {
        path:'/main/testPaperForm',
        element:lazy(() => import('../pages/TestPaperManage/TestPaperForm')),
        meta:{
            menuKey:"testPaperManage",
            render:false,
        }
    },
    {
        path:'/main/testManage',
        element:lazy(() => import('../pages/TestManage/TestManage')),
        title:'题目管理',
        meta:{
            render:true, 
            menuKey:'testManage',
            roles:[1],
            // icon:ContainerOutlined
        }
    },
    {
        path:'/main/testForm',
        element:lazy(() => import('../pages/TestManage/TestForm')),
        meta:{
            render:false,
            menuKey:"testManage",
        }
    },
    {
        path:'/main/autoGenTest',
        element:lazy(() => import('../pages/AutoGenTest/AutoGenTest')),
        title:'线下作业',
        meta:{
            render:true,
            menuKey:'autoGenTest',
            roles:[1, 100],
            // icon:ContainerOutlined
        }
    },
    {
        path:'/main/testPaperConvert',
        element:lazy(() => import('../pages/TestPaperConvert/TestPaperConvert')),
        title:'作业转换',
        meta:{
            // icon:ReadOutlined,
            menuKey:"testManage",
            render:false,
        }
    },
    {
        path:'/main/createTest',
        element:lazy(() => import('../pages/CreateTest/CreateTest')),
        title:'组建作业',
        meta:{
            menuKey:"createTest",
            render:true,
            roles:[1, 100],
            // icon:FileDoneOutlined
        }
    },
    {
        path:'/main/schoolManage',
        element:lazy(() => import('../pages/SchoolManage/SchoolManage')),
        title:'学校管理',
        meta:{
            menuKey:"schoolManage",
            render:true,
            roles:[1],
            // icon:BankOutlined
        }
    },
    {
        path:'/main/classManage',
        element:lazy(() => import('../pages/ClassManage/ClassManage')),
        meta:{
            menuKey:"schoolManage",
            render:false,
        }
    },
    {
        path:'/main/userManage',
        element:lazy(() => import('../pages/UserManage/UserManage')),
        title:'用户管理',
        meta:{
            menuKey:"userManage",
            render:true,
            roles:[1],
            // icon:UserAddOutlined
        }
    },
]

export default RouterData;