class LoadingPublisher {
    public loadingCount:number;
    public subscriptions:any;

    constructor(){
        this.loadingCount = 0;
        this.subscriptions = [];
        
    }

    add(){
        this.loadingCount++;
        this.subscriptions.forEach((f:any) => f(this.loadingCount))
    }

    sub(){
        this.loadingCount--;
        this.subscriptions.forEach((f:any) => f(this.loadingCount))
    }

    get(){
        return this.loadingCount;
    }

    subscribe(f:any){
        this.subscriptions.push(f);
    }
}

export default new LoadingPublisher();